import React from "react"
import { Typography, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import apartment from "../../images/apartmentSelected.svg"

export const ListingDetailBasicInfo = () => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  const WebActiveCase = () => (
    <Grid container xs={12} sm={4}>
      <div style={{ marginLeft: "auto" }}>
        <Typography style={{ textAlign: "right" }} className={classes.active}>
          ACTIVE
        </Typography>
        <Typography style={{ textAlign: "right" }} className={classes.header}>
          $859,000
        </Typography>
      </div>
      <div>
        <img
          style={{
            width: "60px",
            height: "70px",
          }}
          src={apartment}
          alt="icon"
        />
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Typography className={classes.listedBy} htmlFontSize variant="caption">
          <b>Listed By:</b> RE/MAX Crest Realty
        </Typography>
      </div>
    </Grid>
  )

  const MobileActiveCase = () => (
    <div>
      <div style={{ display: "flex" }}>
        <img
          style={{ width: "60px", height: "70px" }}
          src="https://www.fisherly.com/frontend/icons/apartment-selected.svg"
          alt="icon"
        />
        <div>
          <Typography className={classes.active}>ACTIVE</Typography>
          <Typography className={classes.header}>$859,000</Typography>
        </div>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Typography className={classes.listedBy} htmlFontSize variant="caption">
          <b>Listed By:</b> RE/MAX Crest Realty
        </Typography>
      </div>
    </div>
  )

  return (
    <div className={classes.root}>
      <Grid container xs={12}>
        <Grid container xs={12} sm={8}>
          <div>
            <Typography className={classes.header} variant="h1">
              3106 - 1068 HORNBY STREET
            </Typography>
            <Typography className={classes.subHeader} gutterBottom>
              Downtown VW, Vancouver, BC
            </Typography>
          </div>
        </Grid>
        {matches ? <MobileActiveCase /> : <WebActiveCase />}
      </Grid>
      <Typography className={classes.footer} variant="caption">
        RE/MAX Crest Realty
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  header: {
    fontSize: "1.75em",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.text.primary,
  },
  subHeader: {
    fontSize: "18px",
    fontWeight: 400,
    color: theme.text.primary,
  },
  active: {
    fontSize: "24px",
    fontWeight: 700,
    color: theme.text.lightBlue,
  },
  footer: {
    fontSize: "16px",
    color: theme.text.primary,
  },
  listedBy: {
    fontSize: "11px",
    color: theme.text.primary,
  },
}))
