import React from "react"
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core"
import { withStyles, makeStyles } from "@material-ui/core/styles"

export const ListingDetailTableView = ({ rows }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant="h2">
        Technical Information
      </Typography>
      <TableContainer>
        <Table size="small" className={classes}>
          <TableBody>
            {[...rows].map(row => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>{row.value}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const StyledTableCell = withStyles(theme => ({
  body: {
    fontSize: 13,
    height: "13px",
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginBottom: "1em",
  },
  header: {
    fontSize: "22px",
    fontWeight: 400,
    color: theme.text.primary,
    marginBottom: "0.5em",
  },
  table: {
    minWidth: 700,
  },
}))
