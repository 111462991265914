import React from "react"
import { Typography, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

export const ListingDetailDescription = ({ header, description }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.header} variant="h2">
          {header}
        </Typography>
        <Typography className={classes.content}>{description}</Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  header: {
    fontSize: "22px",
    fontWeight: 400,
    color: theme.text.primary,
    marginBottom: "0.5em",
  },
  content: {
    fontSize: "15px",
    color: theme.text.primary,
  },
}))
