import React from "react"
import { HeaderImageGrid } from "./headerImageGrid"
import { Grid, Typography, Link } from "@material-ui/core"
import { ListingDetailBasicInfo } from "./listingDetailBasicInfo"
import { ListingDetailDetails } from "./listingDetailDetails"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "../../components/Theme"
import { ListingDetailDescription } from "./listingDetailDescription"
import { ListingDetailLocation } from "./listingDetailLocation"
import { ListingDetailTableView } from "./listingDetailTableView"
import { listingDescription } from "../../constants/db"
import { amenities } from "../../constants/db"
import { features } from "../../constants/db"
import { technicalInformation } from "../../constants/db"
import { floorDetails } from "../../constants/db"
import { ListingDetailRightPanel } from "./listingDetailRightPanel"
import { makeStyles } from "@material-ui/core/styles"
import logo from "../../images/logo.svg"

const DrawLine = () => {
  return (
    <Grid container xs={12}>
      <div
        style={{
          height: "1px",
          backgroundColor: "#9b9b9b",
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      ></div>
    </Grid>
  )
}

export const ListingDetailContent = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "block" }}>
        <HeaderImageGrid />
        <Grid style={{ padding: "4%" }} container xs={12}>
          <Grid container style={{ paddingRight: "2%" }} sm={12} md={8}>
            {/* Basic information */}
            <ListingDetailBasicInfo />
            <DrawLine />
            {/* DETAILS */}
            <ListingDetailDetails />
            <DrawLine />
            {/* DESCRIPTION */}
            <ListingDetailDescription
              header={listingDescription.header}
              description={listingDescription.description}
            />
            <DrawLine />
            {/* Amenities */}
            <ListingDetailDescription
              header={amenities.header}
              description={amenities.description}
            />
            <DrawLine />
            {/* Features */}
            <ListingDetailDescription
              header={features.header}
              description={features.description}
            />
            <DrawLine />
            {/* Location */}
            <ListingDetailLocation />
            <DrawLine />
            {/* Technical Information */}
            <ListingDetailTableView rows={technicalInformation} />
            <DrawLine />
            {/* Floor Area (sq. ft.) */}
            <ListingDetailTableView rows={floorDetails} />
          </Grid>
          <Grid
            style={{
              // backgroundColor: "brown",
              justifyContent: "center",
            }}
            container
            xs={12}
            sm={12}
            md={4}
          >
            <ListingDetailRightPanel />
          </Grid>
        </Grid>
        <div className={classes.footer}>
          <Typography className={classes.footerText}>
            <b>Disclaimer</b>: Listing data is based in whole or in part on data
            generated by the Real Estate Board of Greater Vancouve and Fraser
            Valley Real Estate Board which assumes no responsibility for its
            accuracy.
          </Typography>
          <DrawLine />
          <Typography className={classes.footerText}>
            <Link
              style={{ color: "#333 " }}
              href="https://www.fisherly.com/terms-and-conditions"
            >
              Terms & Conditions
            </Link>
            &nbsp; &#8226; &nbsp;
            <Link
              style={{ color: "#333 " }}
              href="https://www.fisherly.com/privacy-policy"
            >
              privacy policy
            </Link>
          </Typography>
          <img className={classes.logo} src={logo} alt="Fisherly logo" />
        </div>
      </div>
    </ThemeProvider>
  )
}

const useStyles = makeStyles(theme => ({
  footer: {
    paddingLeft: "4%",
    paddingRight: "4%",
    display: "block",
    marginTop: "1em",
  },
  footerText: {
    fontSize: "12px",
    color: "#333",
    textAlign: "center",
  },
  logo: {
    position: "relative",
    height: "2.2em",
    padding: "1em",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
}))
