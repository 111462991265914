export const typeOptions = ["house", "townhouse", "apartment"]
export const priceOptions = [
  "0",
  "25000",
  "50000",
  "75000",
  "100000",
  "125000",
  "150000",
  "175000",
  "200000",
  "225000",
  "250000",
  "275000",
  "300000",
  "325000",
  "350000",
  "375000",
  "400000",
  "425000",
  "450000",
  "475000",
  "500000",
  "550000",
  "600000",
  "650000",
  "700000",
  "750000",
  "800000",
  "850000",
  "900000",
  "950000",
  "1000000",
  "1100000",
  "1200000",
  "1300000",
  "1400000",
  "1500000",
  "1600000",
  "1700000",
  "1800000",
  "1900000",
  "2000000",
  "2500000",
  "3000000",
  "3500000",
  "4000000",
  "4500000",
  "5000000",
  "5500000",
  "6000000",
  "6500000",
  "7000000",
  "7500000",
  "10000000",
  "15000000",
  "20000000",
]
export const sqftOptions = [
  "0",
  "500",
  "750",
  "1000",
  "1250",
  "1500",
  "1750",
  "2000",
  "2250",
  "2500",
  "2750",
  "3000",
  "3250",
  "3500",
  "4000",
  "5000",
  "6000",
  "7000",
  "8000",
  "9000",
  "10000",
]
export const bedBathOption = [
  "0+",
  "0",
  "1+",
  "1",
  "2+",
  "2",
  "3+",
  "3",
  "4+",
  "4",
  "5+",
  "5",
  "6+",
  "6",
  "7+",
  "7",
  "8+",
  "8",
  "9+",
  "9",
]

export const levelOptions = [
  "1+",
  "1",
  "2+",
  "2",
  "3+",
  "3",
  "4+",
  "4",
  "5+",
  "5",
  "6+",
  "6",
]
export const restrictionOptions = [
  "pets",
  "rentals",
  "petsrest",
  "rentalsrest",
  "petsno",
  "rentalsno",
]

export const featureOptions = [
  "sauna",
  "indoor pool",
  "outdoor pool",
  "floorplan",
  "gym",
  "air conditioning",
]
export const mediaOptions = ["floorplan", "virtualtour", "video", "matterport"]
export const parkingOptions = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "1C",
  "2C",
  "3C",
  "4C",
  "5C",
  "6C",
]
export const dayOptions = [
  "1",
  "2",
  "4",
  "7",
  "14",
  "21",
  "30",
  "60",
  "90",
  "180",
  "360",
  "720",
]
export const frontageOptions = [
  "0",
  "10",
  "20",
  "30",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
  "100",
]
export const yearOptions = [
  "1900",
  "1901",
  "1902",
  "1903",
  "1904",
  "1905",
  "1906",
  "1907",
  "1908",
  "1909",
  "2020",
]

export const keywordOptions = {
  "Secondary Suite": [
    "legal suite",
    "coach house",
    "laneway house",
    "basement suite",
  ],
  Parking: [
    "RV and boat parking",
    "single garage",
    "double garage",
    "triple garage",
    "carport",
    "EV ready",
    "attached garage",
    "detached garage",
  ],
  Investor: [
    "rented",
    "investor alert",
    "land assembly",
    "foreclosure",
    "quick sale",
    "immediate possession",
    "tear down",
    "lot value",
    "fixer upper",
    "no strata fee",
  ],
  Features: [
    "Valuted Ceilings",
    "Stainless Steel Appliances",
    "Outdoor Kitchen",
    "Hot Tub",
    "Radiant Heat",
    "Eclipse Doors",
    "Home Automation",
    "Leed Certified",
  ],
  "Property Details": ["gated", "heritage", "corner lot", "cul-de-sac"],
  "Outdoor Spaces": [
    "fenced backyard",
    "rooftop deck",
    "massive deck",
    "private backyard",
    "outdoor fireplace",
    "water feature",
  ],
  "additional rooms": [
    "theater room",
    "wine room",
    "billiards room",
    "bike room",
    "storage locker",
    "gym",
    "spa",
  ],
  updates: ["recently renovated", "new roof", "new furnace", "new appliances"],
}

export default class CommonDB {
  static myInstance = null
  /**
   * @returns {CommonDB}
   */
  static getInstance() {
    if (CommonDB.myInstance == null) {
      CommonDB.myInstance = new CommonDB()
    }

    return this.myInstance
  }

  getYearOptions() {
    const years = []
    for (var i = 1900; i <= new Date().getFullYear(); i++) {
      years.push(i.toString())
    }
    return years
  }
  getAcreOptions() {
    const acres = []

    for (var i = 0; i <= 43560; i = i + 500) {
      acres.push(i.toString())
    }

    const incr = [1, 2, 5, 10, 50, 100, 200, 300, 400, 500, 1000]

    incr.forEach(val => {
      acres.push((val * 43560).toString())
    })

    return acres
  }
}

export const listingDescription = {
  header: "Description",
  description:
    "3106 – 1068 Hornby Street, Vancouver, V6Z 2Y7, “The Canadian” 764 s/f - 1 bed/1 bath condo in the heart of Downtown. Suite feat: open kitch w/granite counter tops, gas FP & stunning SW views of the city and English Bay from floor to ceiling windows. Steps from transit, shopping centres (Robson + Pacific Centre), restaurants, & much more! Amenities include. games/media/party room located on the1st floor off the lobby as well as access to the Sheraton Wall Centre facilities across the street including a fitness centre and swimming pool for a small fee. Currently suite is leased at $2500/month until April 2020. Pets max 2 of any combination but needs strata approval, Airbnb & Rentals OK as long min 1 month, Parking #49 Locker #61. Contingency Reserve $307K, Maint $403.95/mo, Taxes $2110/yr",
}

export const amenities = {
  header: "Amenities",
  description:
    "Elevator Exercise Centre In Suite Laundry Pool; Indoor Storage Swirlpool/Hot Tub",
}

export const features = {
  header: "Features",
  description:
    "ClthWsh/Dryr/Frdg/Stve/DW Disposal - Waste Drapes/Window Coverings Microwave",
}

function createData(name, value) {
  return { name, value }
}

export const technicalInformation = [
  createData("MLS® #", "R2458680"),
  createData("Property Type", "Apartment"),
  createData("Dwelling Type", "Apartment Unit"),
  createData("Home Style", "1 Storey"),
  createData("Kitchens", "1"),
  createData("Year Built", "2000"),
  createData("Parking", "Garage; Underground"),
  createData("Tax", "$2,110 in 2018"),
  createData("Strata No", "LMS4157"),
  createData("Strata Fees", "$404"),
]
export const floorDetails = [
  createData("Main Floor", "767"),
  createData("Total", "767"),
]

function createContactDetail(name, icon, address, email) {
  return { name, icon, address, email }
}
export const contactDetails = [
  createContactDetail(
    "Dave Kenny",
    "https://media.pixilinkserver.com/fisherly/teams/bccondos/david.jpg?w=100&h=100",
    "Sutton West Coast Realty",
    "davidkenny@bccondos.net"
  ),
  createContactDetail(
    "Jark Krysinski",
    "https://media.pixilinkserver.com/fisherly/teams/bccondos/jark.jpg?w=100&h=100",
    "Re/MAX Crest Realty",
    "jark@bccondos.net"
  ),
  createContactDetail(
    "Susan Carhoun",
    "https://media.pixilinkserver.com/fisherly/teams/bccondos/susan.jpg?w=100&h=100",
    "Re/Max Crest Realty",
    "susan@bccondos.net"
  ),
]

function createIconWithName(icon, name) {
  return { icon, name }
}

export const listingDetailIcon = [
  createIconWithName(
    "https://www.fisherly.com/frontend/icons/detailsPage/png/png_sold-history.png",
    "Sold History"
  ),
  createIconWithName(
    "https://www.fisherly.com/frontend/icons/detailsPage/png/png_pet.png",
    "Bylaw Restrictions"
  ),
  createIconWithName(
    "https://www.fisherly.com/frontend/icons/detailsPage/png/png_living-area.png",
    "Room Sizes"
  ),
]
