import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Typography, Grid, Avatar } from "@material-ui/core"
import { contactDetails } from "../../constants/db"
import { listingDetailIcon } from "../../constants/db"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export const ListingDetailRightPanel = () => {
  const classes = useStyles()

  const NameDetail = ({ contact }) => {
    return (
      <Grid container spacing={3} style={{ marginTop: "0.8em" }}>
        <Grid item xs={3}>
          <div>
            <Avatar className={classes.avatar} alt="name" src={contact.icon} />
          </div>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginLeft: "0.5em" }}>
            <Typography className={classes.name}>{contact.name}</Typography>
            <Typography className={classes.subName}>
              {contact.address}
            </Typography>
            <Typography className={classes.subName}>{contact.email}</Typography>
          </div>
        </Grid>
      </Grid>
    )
  }

  const RightPanelIcon = ({ icon }) => {
    return (
      <Grid item xs={4}>
        <div>
          <img className={classes.icon} src={icon.icon} alt={icon.name} />
          <Typography>{icon.name}</Typography>
        </div>
      </Grid>
    )
  }

  return (
    <div className={classes.root} id="rightPanel">
      <Button variant="contained" disableElevation className={classes.btn}>
        Access Sold Prices
      </Button>
      <div className={classes.box}>
        <Typography className={classes.headerText}>
          BC Condos and Homes
        </Typography>
        <Typography className={classes.subHeader}>
          Phone: 604-706-1760
        </Typography>
        {contactDetails.map((contact, index) => (
          <NameDetail contact={contact} key={index} />
        ))}
      </div>
      <div className={classes.box}>
        <Typography className={classes.missingText}>
          See What You're Missing
        </Typography>
        <Grid container>
          {listingDetailIcon.map((icon, index) => (
            <RightPanelIcon icon={icon} key={index} />
          ))}
        </Grid>
        <Button
          style={{ marginTop: "1em", width: "14em" }}
          variant="contained"
          disableElevation
          className={classes.btn}
        >
          Click Here To Register
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center",
    top: 0,
    width: "100%",
    position: "sticky",
    height: "700px",
    marginLeft: theme.breakpoints.down("sm") ? "0%" : "5%",
  },
  btn: {
    width: "100%",
    backgroundColor: "#000",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#111",
      color: "#fff",
    },
  },
  box: {
    padding: "1.5em",
    border: "1px solid #e1e1e1",
    borderRadius: "1px",
    marginTop: "1em",
  },
  headerText: {
    fontSize: "25px",
    fontWeight: 600,
    color: theme.text.primary,
    textAlign: "left",
  },
  subHeader: {
    fontSize: "15px",
    color: theme.text.primary,
    textAlign: "left",
  },
  name: {
    fontSize: "13px",
    fontWeight: 700,
    color: theme.text.primary,
    textAlign: "left",
  },
  subName: {
    fontSize: "13px",
    color: theme.text.primary,
    textAlign: "left",
  },
  avatar: {
    width: "70px",
    height: "70px",
  },
  missingText: {
    fontSize: "22px",
    fontWeight: 700,
    color: theme.text.orange,
    textAlign: "center",
  },
  icon: {
    width: "4.3em",
    height: "4.3em",
    marginRight: "5px",
  },
}))
