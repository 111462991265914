import React, { Component } from "react"
import { HeaderImageGrid } from "../components/listingDetailPage/headerImageGrid"
import { ListingDetailContent } from "../components/listingDetailPage/listingDetailContent"
import { Typography } from "@material-ui/core"

class ListingDetailPage extends Component {
  render() {
    return <ListingDetailContent />
  }
}

export default ListingDetailPage
