import React from "react"
import { Typography, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const details = [
  {
    icon: "https://www.fisherly.com/frontend/icons/detailsPage/svg_bed.svg",
    name: "Beds",
    quantity: "1",
  },
  {
    icon:
      "https://www.fisherly.com/frontend/icons/detailsPage/svg_bathroom.svg",
    name: "Bath",
    quantity: "1",
  },
  {
    icon:
      "https://www.fisherly.com/frontend/icons/detailsPage/svg_built-year.svg",
    name: "Built",
    quantity: "2000",
  },
  {
    icon:
      "https://www.fisherly.com/frontend/icons/detailsPage/svg_living-area.svg",
    name: "Living Area",
    quantity: "767 SqFt.",
  },
  {
    icon:
      "https://www.fisherly.com/frontend/icons/detailsPage/svg_price-sqft.svg",
    name: "$/SqFt.",
    quantity: "1119.95",
  },
  {
    icon: "https://www.fisherly.com/frontend/icons/detailsPage/svg_tax.svg",
    name: "Taxes",
    quantity: "$2,110.37",
  },
]

export const ListingDetailDetails = () => {
  const classes = useStyles()

  function Item({ detail }) {
    return (
      <Grid item xs={6} sm={4} md={4}>
        <div style={{ display: "flex" }}>
          <img className={classes.icon} src={detail.icon} alt="icon" />
          <div>
            <Typography className={classes.itemHead}>{detail.name}</Typography>
            <Typography className={classes.itemDetail}>
              {detail.quantity}
            </Typography>
          </div>
        </div>
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.header} variant="h2">
          Details
        </Typography>
      </div>
      <Grid container spacing={3}>
        {details.map((detail, index) => (
          <Item detail={detail} key={index} />
        ))}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  icon: {
    width: "40px",
    height: "40px",
    marginRight: "5px",
  },
  header: {
    fontSize: "22px",
    fontWeight: 400,
    color: theme.text.primary,
    marginBottom: "0.5em",
  },
  itemHead: {
    fontSize: "14px",
    fontWeight: 700,
    color: theme.text.primary,
  },
  itemDetail: {
    fontSize: "14px",
    color: theme.text.primary,
  },
}))
